﻿.welcome {
    .btn-primary {
        color: #fff;
        background-color: #1b6ec2;
        border: 2px solid #d2d2d2;
        font-weight: 600;
        font-size: 16px;
        margin: 5px 0px 5px 0px;
    }

    .btn-learn {
        color: #1861ac;
        background-color: white;
        border: 2px solid #1861ac;
        font-weight: 600;
        font-size: 16px;
        margin: 20px 0px 5px 0px;
    }

    .btn {
        height: 40px;
    }

    .btn-login {
        color: #fff;
        background-color: #ed7d31;
        border: 2px solid #d2d2d2;
        font-weight: 600;
        font-size: 16px;
        margin: 25px 0px 5px 0px;
    }

    .btn-register {
        margin-top: 40px;
    }

    .div-welcome {
        border: 2px solid #d3d3d3;
        margin-bottom: 1em;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        padding: 1em;
        text-align: left;
        background-color: #f4fafb;
    }

    .welcome-heading {
        font-family: 'GothamMedium',sans-serif;
        margin-bottom: 16px;
        font-weight: 600;
    }

    .header-links {
        font-weight: 600;
        font-size: 20px;
    }

    .text-input {
        font-weight: 500;
        font-size: 16px;
        border: 2px solid #d2d2d2;
        height: 40px;
        margin: 5px 0px 5px 0px;
    }

    .div-padding {
        padding: 15px 0px 0px 25px;
    }

    .text-msg {
        color: #9fa0a0;
        margin: 5px 0px 5px 0px;
    }

    .text-error {
        color: red;
        margin: 5px 0px 5px 0px;
    }

    p {
        text-align: left !important;
        font-size: 14px;
        padding: 0;
        line-height: normal;
        vertical-align: text-top;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 10px;
        padding-left: 0px;
        padding-right: 0px;
        font-weight: 480;
    }

    ul {
        font-size: 14px;
        line-height: normal;
        font-weight: 480;
    }


    a[href*=".pdf"]:not(.btn), a[href*=".mp4"]:not(.btn), a[href*=".html"]:not(.btn) {
        background: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2019.0.0%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20viewBox%3D%22-296%20387%2017.6%2020%22%20style%3D%22enable-background%3Anew%20-296%20387%2017.6%2020%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cstyle%20type%3D%22text/css%22%3E%0D%0A%09.st0%7Bfill%3Anone%3B%7D%0D%0A%09.st1%7Bfill%3A%230085C0%3B%7D%0D%0A%3C/style%3E%0D%0A%3Crect%20x%3D%22-296%22%20y%3D%22387%22%20class%3D%22st0%22%20width%3D%2217.6%22%20height%3D%2220%22/%3E%0D%0A%3Cpath%20class%3D%22st1%22%20d%3D%22M-279.3%2C392.1c0.2%2C0.2%2C0.5%2C0.6%2C0.6%2C1c0.2%2C0.4%2C0.3%2C0.8%2C0.3%2C1.2v11.4c0%2C0.4-0.1%2C0.7-0.4%2C0.9s-0.6%2C0.4-0.9%2C0.4h-15%0D%0A%09c-0.4%2C0-0.7-0.1-0.9-0.4s-0.4-0.6-0.4-0.9v-17.4c0-0.4%2C0.1-0.7%2C0.4-0.9s0.5-0.4%2C0.9-0.4h9c0.4%2C0%2C0.8%2C0.1%2C1.2%2C0.3%0D%0A%09c0.4%2C0.2%2C0.8%2C0.4%2C1%2C0.6L-279.3%2C392.1z%20M-280.1%2C405.3v-9.7h-5.6c-0.4%2C0-0.7-0.1-0.9-0.4s-0.4-0.6-0.4-0.9v-5.6h-7.3v16.6%0D%0A%09L-280.1%2C405.3L-280.1%2C405.3z%20M-285.3%2C388.8v5h5c-0.1-0.3-0.2-0.4-0.3-0.5l-4.2-4.2C-284.8%2C389-285%2C388.9-285.3%2C388.8%22/%3E%0D%0A%3C/svg%3E%0D%0A') left center no-repeat;
        background-size: auto;
        background-size: 14px 13px;
        background-position: left;
        display: inline-block;
        width: auto;
        vertical-align: text-top;
        overflow: visible;
        padding-right: 0;
        padding-top: 0;
        padding-left: 17px !important;
        margin-right: 1px;
        margin-left: 1px;
    }

    .input {
        width: 100%;
        border: none;
        border-radius: 4px;
        display: block;
        margin: 0 auto;
        padding: 8.5px;
        border: 1px solid #d3d0cd;
        -webkit-appearance: none;
        margin: 5px 0px 5px 0px;
    }

    .save-email-wrapper {
        width: 50%;
        float: left;
    }

    .checkbox + label {
        display: inline-block;
        margin-bottom: -4px;
        margin-left: -15px;
        padding-left: 18px;
        line-height: 28px;
        background-image: url(data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20enco…22404.3%22%20x2%3D%22-265%22%20y2%3D%22389.6%22/%3E%0D%0A%3C/svg%3E%0D%0A);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-color: #fff;
        width: 18px;
        height: 19px;
        background-size: 100%;
        overflow: hidden;
        border-radius: 3px;
        border: 1px solid #d3d0cd;
    }

    .checkbox-wrapper {
        margin: 20px 0px 0px 10px;
    }

    .login {
        width: 100%;
        border-radius: 4px;
        background-color: #ff7f32;
        display: block;
        border: none;
        color: #fff;
        text-align: center;
        margin: 0;
        font-family: 'GothamMedium',sans-serif;
        text-transform: uppercase;
        margin: 30px 0px 0px 0px;
    }

    .btn.lg-button {
        background-color: #ff7f32;
        border-radius: 0px;
    }

    .btn {
        width: 100%;
    }

    .btn.lg-action-button, .btn.md-action-button, .btn.sm-action-button {
        background-color: #009bdf;
    }

    .submit-container {
        margin: 20px 0px 0px 0px;
        border-radius: 0px;
    }

    .btn.lg-button:hover {
        background-color: #f16f21;
        color: #fff;
    }
}

.verify-email-modal .modal-header {
    border-bottom: none;
}
