﻿.provider-navigation {
    top: 20px;
    text-transform: uppercase;

    .navbar-content{
        a {
            color: white;
            padding: 15px;
        }

        .navbar-brand {
            font-size: 18px;
            line-height: 20px;
            font-weight: 700;
            padding-right: 50px;
            padding-left: 20px;
        }
    }
}

a.nav-link {
    border: none;
    background: none;
    color: white;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 20px;
    font-weight:700;
}
button.collapsed .toggle, .open .caret {
    -moz-transform: scale(1, -1);
    -webkit-transform: scale(1, -1);
    -o-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}

.caret {
    color: #FEB71B;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropdown-menu {
    display: none;
}

.dropdown-menu > li > a, #subnav .dropdown-menu > li > a {
    padding: 10px 20px;
}

.dropdown-menu > li > a, .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover, .nav li.open > a, .nav li.open > a:focus, .nav li.open > a:hover, a.navbar-brand:hover, .navbar-brand, .navbar-nav .open .dropdown-menu .dropdown-header, .navbar-nav .open .dropdown-menu > li > a, #subnav a, .toggle {
    color: #FFF;
    text-decoration: none;
}

.dropdown-toggle::after {
    content: none;
}
@media screen and (min-width: 971px) {
    .dropdown:hover .dropdown-menu {
        display: block;
    }

    .dropdown:hover .caret {
        -moz-transform: scale(1, -1);
        -webkit-transform: scale(1, -1);
        -o-transform: scale(1, -1);
        -ms-transform: scale(1, -1);
        transform: scale(1, -1);
    }
}
ul.navbar-nav li ul.dropdown-menu li {
    list-style: none;
}
#subnav li.dropdown a {
    font-size: 15px;
}
.navbar-nav > li > .dropdown-menu {
    padding: 0;
    background-color: #007FA9;
    border-top: 5px solid #FEB71B;
}
.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover, .nav > li > a:focus, .nav > li > a:hover, #subnav a:hover, .nav li.open > a, .nav li.open > a:focus, .nav li.open > a:hover, a.navbar-brand:hover {
    background-color: #006181;
}
