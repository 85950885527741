﻿/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
    margin-left: 4px;
    margin-right: 4px;
}

.btnGrpButtons {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
    margin-left: 8px;
    margin-right: 8px;
    float: right;
}

.btnRowDiv {
    display: flex;
    flex-direction: row;
}

h3 {
    color: rgb(60, 105, 189);
    margin-top: 28px;
}

.sub-header {
    font-weight: 750;
    font-size: 1.56rem;
    color: #00c1df;
    text-transform: capitalize;
    margin: 1px 0px 15px 0px;
}

.identCheckbox {
    margin-left: 20px;
}

.required-field::before {
    /*test comment*/
    content: "* ";
    color: red;
}

.pull-right {
    float: right;
}

.input-error {
    border: 1px solid #db2269;
}

.error {
    color: #db2269;
    font-size: 0.625em;
    position: relative;
}
.page-error {
    color: #db2269;
    font-size: 20px;
    position: relative;
}

.form-field .dropdown {
    color: #495057;
    background: none;
    border-radius: 5px;
    border-block-color: lightgray;
    justify-content: left;
    background-color: white !important;
}

.dropdown:hover {
    color: #495057;
}

.dropdown:focus:not(:focus-visible) {
    outline: 0;
    color: #495057;
}

Dropdown:hover {
    background: yellow;
    background-color: pink;
}

.grid-header {
    background-color: #2f528f;
    color: white;
}

.grid-rows {
    background-color: #b4c7e7;
    color: black;
}

.arrow {
    border: solid #495057;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    margin-top: 8px;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.add-button {
    margin-right: 10px;
    padding: 0px;
    font-size: 25px;
    font-weight: 900;
    background-color: white;
    color: blue;
}


.grid-rows-danger {
    background-color: rgba(255, 0, 0, 0.2);    
    color: black;
}

.container {
    margin-top: 175px;
}

.info{
    padding-bottom: 20px;
}

