﻿.application-status {
    border: 1px solid;
    padding: 18px 12px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 28px;
    background: #F5F5F5;
    height: 100px;
    margin-top: 40px;
}

.circularBar {
    height: 110%;
    margin: 3px 0px -40px 0px;
}

.nav-link {
    font-size: 32px;
    border: 2px solid #BFBFBF;
    border-radius: 5px;
    background: #0040a1;
    margin: 10px 0px 15px 15px;
}

.nav-link1 {
    font-size: 32px;
    color: white;
    border: 2px solid #BFBFBF;
    border-radius: 5px;
    background: #0040a1;
    margin: 10px 0px -10px 30px;
    padding: 10px 0px 5px 15px;
}

.link {
    font-size: 14px;
    color: white;
}

.nav-bar {
    border: 2px solid lightgrey;
    border-radius: 5px;
    margin: 0px 0px 40px 0px;
    height: 95%;
}

.titleBar {
    font-size: 18px;
    white-space: pre-line;
    font-weight: 500;
    color: white;
    margin: -1px 0px 10px 0px;
    padding: 10px 0px 10px 15px;
}

.titleBar-NotSubmitted {
    font-size: 18px;
    white-space: pre-line;
    font-weight: 500;
    color: white;
    margin: -1px 0px 10px 0px;
    padding: 5px 0px 5px 15px;
}

.title {
    padding-top: 10px;
    font-size: 18px;
    white-space: pre-line;
    font-weight: 500;
    margin: 0px 0px 0px 15px;
}

.description {
    color: #5B5B5B;
    font-size: 14px;
    margin: 5px 0px 0px 15px;
}

.groupDetails {
    color: #5B5B5B;
    font-size: 12px;
    margin: 10px 0px 0px 15px;
    padding: 0px 0px 0px 15px;
}

.header {
    color: #5B5B5B;
    font-size: 14px;
    font-weight: bold;
    margin: 10px 0px -10px 15px;
    padding: 0px 0px 10px 15px;
}

.providerTable {
    height:200px;;
    color: #5B5B5B;
    font-size: 14px;
    margin: 0px 0px 0px 0px;
    padding: 0px 25px 0px 25px;
}

.providerdescription {
    color: #5B5B5B;
    font-size: 14px;
    margin: 10px 0px -5px 15px;
    padding: 0px 30px 10px 15px;
}

.status-icon {
    margin: 5px;
    font-weight: 800;
}

.status-name {
    text-transform: uppercase;
    font-weight: 600;
    margin: 5px;
}

.icon-pencil:before {
    content: "\270E";
    font-size: 16px;
    display: inline-block;
    width: 24px;
    border: 0px solid black;
    border-radius: 50% 50%;
    background: #808080;
}

.icon-cross-mark:before {
    content: "\274C";
    display: inline-block;
    width: 24px;
    border: 0px solid black;
    border-radius: 50% 50%;
    background: rgb(176,10, 0);
}

.icon-check-mark:before {
    content: "\2713";
    display: inline-block;
    width: 24px;
    border: 0px solid black;
    border-radius: 50% 50%;
    background: rgb(0,176,80);
}

.icon-exclamation-mark:before {
    content: "\0021";
    display: inline-block;
    width: 24px;
    border: 0px solid black;
    border-radius: 50% 50%;
    background: rgb(255,192,0);
}


.application-status-pending {
    background: #fec107;
}

.application-status-notSubmitted {
    background: #6c757e;
}

.application-status-approved {
    background: #27a746;
}

.application-status-denied {
    background: #dc3546;
}


.tileGrid-header {
    background-color: lightgrey;
    color: black;
}

.MuiDataGrid-columnHeaderTitle{
    font-weight:bold !important;
}

.tableEmptyMsg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiDataGrid-row:nth-child(odd) {
    background-color: white;
}


.MuiDataGrid-row:nth-child(even) {
    background-color: lightgrey;
}