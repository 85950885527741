﻿@import '../_variables.scss';
@import '~react-bootstrap-typeahead/css/Typeahead.css';

body {
    padding-bottom: 100px;
}

.div-pageTitle {
    padding: 2px 0px;
    text-align: left;
    font-weight: bold;
    text-transform: capitalize;
    border-bottom: 1px solid rgb(211,208,205);
    margin-bottom:15px;

    label {
        //font-family:
        font-size: 31.5px;
        //font-weight: 600;
        color: #00519d;
        line-height: 1;
    }
}

.div-roster {
    border: 2px solid #2f528f;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 1em;
    text-align: left;
    background-color: #b4c7e7;
    padding: 40px 60px 10px 40px;
}

.a-withUnderline{
    text-decoration:underline;
}

.navigation-footer {
    border: 2px solid $dk-blue;
    width: 100%;
    display: flex;
    margin-right: 0px;
    background: white;
    margin-left: 0px;

    .button-group {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex: 1;
        height: 100%;

        button {
            margin: 10px 10px;
            background-color: $dk-blue;
            border-radius: 0px;
            width: auto;
        }
    }
}

.practice-information {
    Label {
        margin-top: 5px;
    }

    .api-error {
        font-size: 24px;
    }

    .edit-tax-id {
        margin-top: 13px;
        background-color: #00c1df;
        border-color: #00c1df;
        color: white;
    }
}

.practice-limitations {
    Input, select {
        display: inline;
        width: auto;
    }

    Label {
        margin-top: 0px;
        margin-left: 10px;
    }
}

.form-check-input {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 5px;
    position: relative;
    padding: 5px;
}

.hours-of-operation {
    input[type="time"] {
        //margin-top: 8px;
    }
    button {
        margin-top: 32px;
        margin-right: 15px;
        width: 35px;
    }
}

.checkbox-label {
    margin-left: 10px;
}

::-webkit-file-upload-button {
    background: #2f528f;
    color: white;
}

.margin-left {
    margin-left: 1em;
}

.custom-modal-style {
    width: 897px;
    height: 400px;
}

.phoneInputMask {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    display: block;
    border-style: solid;
    border-color: rgb(206, 212, 218);
    border-radius: 0.25rem;
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1.5;
    transition-property: border-color, box-shadow;
    transition-duration: 0.15s, 0.15s;
    transition-timing-function: ease-in-out, ease-in-out;
    transition-delay: 0s, 0s;
}

.provider-information {
    .btn {
        width: 100%;
    }

    .gender-radio span {
        margin-right: 25px;
    }
}

