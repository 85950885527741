﻿// SHARP SANS FONT VARIABLES
$sharp-sans: 'SharpSans', sans-serif;
$sharp-weight-light: 100;
$sharp-weight-semi-light: 300;
$sharp-weight-regular: 400;
$sharp-weight-medium: 600;
$sharp-weight-semi-bold: 600;
$sharp-weight-bold: 700;
$sharp-weight-semi-black: 800;
$sharp-weight-black: 900;
$font-weight-bold: 600;
$font-weight-normal: 400;

// FONTS
$default-font-family: "SharpSans", "Arial", sans-serif;
$font-family-icon: 'icomoon';

// DEFAULT COLORS
$white: #ffffff;
$black: #000000;

$dk-gray: #364248;
$md-gray: #888B8D;
$lt-gray: #C8C8C8;

$dk-blue: #00539D;
$md-blue: #6AAAE4;
$lt-blue: #97CAEB;

$dk-teal: #008FBE;
$md-teal: #00C1DE;
$lt-teal: #96DAEA;

$dk-green: #009877;
$md-green: #00C4B3;
$lt-green: #78DED4;

$dk-navy: #001E61;
$md-navy: #5887DA;
$lt-navy: #A5C5ED;

$dk-yellow: #FFB71B;
$md-yellow: #FFC844;
$lt-yellow: #FCD672;

$dk-orange: #FF4D00;
$md-orange: #FF7F30;
$lt-orange: #FFB98E;

$dk-red: #CE0E2D;
$md-red: #F4436C;
$lt-red: #FE9BB1;

$dk-purple: #691F74;
$md-purple: #93358D;
$lt-purple: #CB8BDA;

// GLOBAL ELEMENT COLORS
$progress-gray: #D8D8D8;
$default-text-color: $dk-gray;
$text-link-color: $md-navy;
$text-link-hover-color: $dk-navy;
$input-bg-color: #F4F4F4;
$error-color: $md-red;
$error-orange-bg: #FFF8F3;
$error-red-bg: #fff2f5;
$error-red-bg-border: #feb8c8;
$system-purple-bg: rgba(203, 139, 218, 0.07);
$system-blue-bg: rgba(151, 202, 235, 0.15);
$system-green-bg: #EBF7F5;
$success-color: $dk-green;
$sub-text-gray: #ACAEB0;
$info-banner-bg: #E5F8FB;
$gray-bg: #f0f3f3;
$banner-teal: #30AAD3;
$evisit-border: #96DAEA;
$video-border: #78DED4;
$inperson-border: #FCD672;
$status-closed: #53575A;
$menu-link-border-color: #0488b3;
$video-visit-border: #DDDDDD;
