﻿.document-link {
    .document-icon:before {
        content: '\1F5CE';
        padding: 5px;
        color: #0366d6;
    }

    a {
        text-decoration: underline;
    }
}
