﻿.dashboard-nav-link a.nav-link {
    font-size: 28px;
    border: 2px solid #BFBFBF;
    height: 100%;
    background: rgb(245,250,251);

    .icon {
        margin-top: 30px;
        font-size: 100px;
        /*background-color: #4472C4;*/
    }

    .title {
        color: #4472C4;
        padding-top: 10px;
        font-size: 28px;
        white-space: pre-line;
    }

    .description {
        color: #5B5B5B;
        font-size: 20px;
    }

    .title:hover, .description:hover {
        text-decoration: none;
    }
}

.dashboard-new-link {
    .new-link {
        font-size: 32px;
        border: 2px solid #BFBFBF;
        border-radius: 5px;
        background: #0040a1;
        margin: 25px 0px 20px 15px;
        padding: 10px 0px 3px 15px;
        text-transform: none;
    }

    .title {
        padding-top: 10px;
        font-size: 18px;
        white-space: pre-line;
        font-weight: 500;
        margin: 20px 0px 0px 0px;
    }

    .description {
        color: #5B5B5B;
        font-size: 14px;
        margin: 5px 0px 0px 0px;
    }

    .link {
        font-size: 14px;
        color: white;
    }

    .title:hover, .description:hover {
        text-decoration: none;
    }

    .new-bar {
        border: 2px solid lightgrey;
        border-radius: 5px;
        margin: 0px 0px 40px 0px;
    }
}
