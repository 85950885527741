﻿.header {
    background: white;

    .headerTop {
        padding-top: 15px;
        height: 40px;
        margin: 0 auto;
    }

    .logo-and-menu {
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
    }

    .logo {
        float: left;
        height: 40px;
        margin-bottom: 20px;
    }

    .headerTop > .health-services {
        float: right;
        margin: 23px 20px 15px 20px;
        color: #0085c0;
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        text-transform: uppercase;
        font-family: "GothamMedium", sans-serif;
    }

    .health-services .sw-utility-link {
        margin-bottom: 10px;
        color: #0085c0;
    }

    .provider-login {
        font-size: 14px;
        text-transform: uppercase;
        height: 42px;
        line-height: 42px;
        margin: -50px 0 0 35px;
        padding: 0 20px;
        width: 185px;
        background-color: #FEB71B;
        font-weight: 700;
        color: #003DA6;
        border-radius: 0px;
        border: none;
        position: relative;
    }

    .provider-login:hover {
        color: #fff;
        background-color: #5887da;
    }

    .page-title > span {
    }

    .provider-navigation {
        top: 75px;
        position: absolute;
        width: 100%;
        background: #007FA9;
        margin: 1px auto;
        text-align: center;
        color: white;
        z-index: -1;

        .navbar-content {
            max-width: 1440px;
            margin: 3px auto;
            padding: 0px;
        }
    }
    /*hamburger Menu*/
    .menu-icon {
        padding: 12px 0 14px 25px;
        top: 0px;
        transition: 0.2s ease-in-out;
        cursor: pointer;
        display: inline-block;
        position: relative;
        margin-bottom: 5px;

        .animated-icon3 {
            height: 40px;
            width: 40px;
            color: #00539d;
        }
    }

    .tooltip-hbm .tooltiptext {
        top: 16px;
        right: -4px;
        padding: 0 2px 0 0;
        transition: opacity 0.3s;
        background-color: #fff;
        visibility: hidden;
        color: #00539d;
        position: absolute;
        z-index: 1;
        font-weight: normal;
        font-size: 16px;
    }

    .tooltip-hbm:hover .tooltiptext {
        opacity: 1;
        visibility: visible;
    }

    .tooltip-hbm:not(.collapsed) .tooltiptext {
        opacity: 0;
    }
    /*Hamburger animation*/
    .animated-icon3 {
        width: 30px;
        height: 20px;
        position: relative;
        margin: 0px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
    }

    .animated-icon3 span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }

    .animated-icon3 span {
        background: #00539d;
    }

    .animated-icon3 span:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .animated-icon3 span:nth-child(2) {
        top: 10px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .animated-icon3 span:nth-child(3) {
        top: 20px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .tooltip-hbm:not(.collapsed) .animated-icon3 span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 0px;
        left: 0px;
    }

    .tooltip-hbm:not(.collapsed) .animated-icon3 span:nth-child(2) {
        width: 0%;
        opacity: 0;
    }

    .tooltip-hbm:not(.collapsed) .animated-icon3 span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 28px;
        left: 0px;
    }
    /*END HAMBURGER ANIMATION*/
    /*HAMBURGER MENU STLYES*/
    #navbarSupportedContent1 {
        z-index: 1035;
        margin-top: -13px;
        background: white;
        box-shadow: 0px 3px 3px 0px rgb(0 0 0 / 20%);
        width: 100%;
    }

    .ac-container {
        text-align: left;

        .menu-links {
            margin: 0;
            padding: 0;
            list-style: none;

            li a {
                color: #007FA9;
                font-size: 17px;
                font-weight: 700;
                display: block;
                padding: 20px 0 0 0;
                line-height: 1.5em;
            }

            li span {
                font-weight: 400;
                font-size: 13px;
            }
        }
    }

    .ac-container label {
        position: relative;
        z-index: 20;
        display: block;
        cursor: default;
        font-size: 28px;
        font-weight: 500;
    }

    .ac-container article {
        overflow: visible;
        padding-right: 30px;
    }

    .menu-col-one {
        padding-left: 20px;
    }

    .menu-col-one, .menu-col-two, .menu-col-three, .menu-col-four {
        width: 25%;
        float: left;
        position: relative;
        padding-bottom: 30px;
    }

    @media only screen and (max-width: 970px) {
        .ac-container {
            padding-top: 20px;
            margin-top: 0;
            border-top: 2px solid #FEB71B;
        }

        .header .menu-btn:checked ~ div.menu .ac-container {
            height: 95%;
        }

        #navbarNavDropdown {
            display: inline-block;
        }

        .navbar-header {
            margin-right: 0;
            margin-left: 0;
        }

        .navbar-brand {
            margin-left: -15px;
        }

        .navbar-nav {
            display: inline-block;

            li {
                float: left;
            }
        }

        .ac-container {
            width: 100%;
            max-width: 1600px;
            margin: 0 auto;
            padding-top: 20px;
            margin-top: -8px;
            border-top: 3px solid #FEB71B;
            height: 600px;
            background: white;
        }

        .ac-container label {
            font-size: 20px;
        }
    }

    @media screen and (min-width: 971px) {

        .ac-container {
            width: 100%;
            max-width: 1600px;
            margin: 0 auto;
            padding-top: 20px;
            margin-top: -8px;
            border-top: 3px solid #FEB71B;
            height: 500px;
            background: white;
        }

        .ac-container label {
            font-size: 20px;
        }
    }
}
