﻿.dashboard {
    

    .subtitle {
        color: #5B5B5B;
        margin-top: 28px;
        margin-bottom: 28px;
    }

    .badge-number {
        float: right;
        position: relative;
        top: -20px;
        left: 15px;
        border-radius: 50%;
        background: rgb(192,0,0);
        color: white;
        padding: 10px 18px;
        font-weight: 600;
        font-size: 18px;
    }
}

.dashboard-modal > div:first-child {
    padding: 10px 0px;
}

.dashboard-modal-footer > button {
    border-radius: 0px;
    text-transform: uppercase;
    font-family: "GothamMedium", sans-serif;
    font-weight: 600;
    padding: 0 17px;
}

.dashboard-modal-footer > .primary {
    background-color: #001E61;
    color: white;
}

.dashboard-modal-footer > .secondary {
    background-color: white;
    color: #001E61;
    border: 2px solid #001E61;
}

.tax-id-modal img {
    height: 22px;
    padding: 0px 20px;
}

.applicationgrouping {
    color: #003DA6;
    position: relative;
    border-bottom: 2px solid #f7f7f7;
}

.toggleArrow {
    color: #003DA6;
    margin: 10px 0px 0px 75px;
    padding: 0px 0px 0px 75px;
}
.groupHeader {
    padding: 0 0 10px;
}

.applicationgrouping:hover {
    color: #008fbe;
}

.toggleArrow:hover {
    color: #008fbe;
}