﻿.verify-email {
    .envelope-icon {
        height: 200px;
        width: 200px;
        margin-bottom: 20px;
        color: #183153;
    }

    button {
        margin-top: 20px;
        border: none;
        background: none;
        color: #FEBD11;
        font-weight: bold;
    }

    .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:disabled {
        border: none;
        background: none;
        color: #17469E;
    }

    .btn-secondary:disabled {
        color: grey;
    }
}
