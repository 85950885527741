﻿.footer {
    .border-line {
        max-height: 30px;
        background-image: linear-gradient( 90deg,#fcb61a 65%,#018ebc 35%);

        img {
            max-height: 30px;
            height: auto;
        }
    }

    .footer-links {
        padding-top: 50px;
        max-width: 1600px;
        margin: 0px auto;

        a {
            color: #007FA9;
        }

        a:hover {
            color: #003DA6;
            text-decoration: none;
        }

        .footer-local-links, .footer-legal-links {
            list-style: none;
            list-style-image: none;
            font-weight: 400;
            line-height: 1.5em;
        }
    }

    .facebook-icon {
        color: #007FA9;
        background: url(../../images/Branding/sprites-2022.png) no-repeat -210px -158px;
        width: 24px;
        height: 23px;
        display: block;
    }

    .twitter-icon {
        color: #007FA9;
        background: url(../../images/Branding/sprites-2022.png) no-repeat -158px -157px;
        width: 28px;
        height: 23px;
        display: block;
    }

    .linkedin-icon {
        color: #007FA9;
        background: url(../../images/Branding/sprites-2022.png) no-repeat -260px -158px;
        width: 24px;
        height: 23px;
        display: block;
    }

    .apple-app-btn {
        background: url(../../images/Branding/sprites-2022.png) no-repeat -275px -48px;
        width: 145px;
        height: 43px;
        display: inline-block;
    }

    .gp-app-btn {
        background: url(../../images/Branding/sprites-2022.png) no-repeat -420px -48px;
        width: 145px;
        height: 43px;
        display: inline-block;
    }

    .app-icons {
        padding-bottom: 0.5em;

        .app-store-icon {
            display: inline-block;
            width: 144px;
        }
    }

    .footer-social {
        list-style: none;
        list-style-image: none;
        position: relative;
        right: 15px;

        li {
            display: inline-block;
            padding-left: 15px;
        }
    }

    .row {
        padding-right: 15px;
    }
}
