﻿.sign-up {

    .form-card {
        border: 2px solid #d3d3d3;
        margin-bottom: 1em;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        padding: 1em;
        text-align: left;
        width: 600px;
        background-color: #f4fafb;
    }

    .form {
        padding: 1em;
    }

    .btn-submit {
        width: 100%;
        border-radius: 4px;
        background-color: #ff7f32;
        display: block;
        border: none;
        color: #fff;
        text-align: center;
        margin: 0;
        font-family: 'GothamMedium',sans-serif;
        text-transform: uppercase;
        margin: 30px 0px 0px 0px;
    }

    .btn-submit:hover {
        /*background-color: #f16f21;*/
    color: #fff;
    }

    
}
